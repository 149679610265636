interface Options {
  t: (key: string) => string;
  te: (key: string) => boolean;
}

interface ParsedError {
  code: string;
  message: string;
}

const getErrorLocalePathMessage = (error: string, options: Options): ParsedError => {
  try {
    const errorObj = JSON.parse(error);
    const code = errorObj?.code || "";

    const errorKey = code ? `errors.${code}` : "";
    const hasTranslation = errorKey && options.te(errorKey);

    const message = hasTranslation ? options.t(errorKey) : errorObj?.message || "An error occurred";

    return { message, code };
  } catch (parseError) {
    return { message: error || "An error occurred", code: "" };
  }
};

export default getErrorLocalePathMessage;
